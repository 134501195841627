// Base Button Styles
button {
  background: none;
  border: none;
}
button,
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button {
  height: 5.2rem;
  padding: 1rem 1.6rem;
  font-weight: bold;
  border-radius: 999px;
  text-transform: uppercase;
  transition: 0.4s;

  &--full {
    width: 100%;
  }
}

.button-primary,
.button-secondary,
.button-provider {
  border-style: solid;
  border-width: 0.2rem;
}

// Primary Button
.button-primary {
  &--blue {
    color: var(--white);
    background: var(--nbs-navy-blue);
    border-color: var(--nbs-navy-blue);
  }
}

// Secondary
.button-secondary {
  &--white {
    color: var(--white);
    border-color: var(--white);

    svg {
      stroke: var(--white);
    }

    &:hover {
      background: rgba(255, 255, 255, 0.12);
    }
  }
}

// Provider Buttons
.button-provider {
  position: relative;
  color: var(--nbs-navy-blue);
  border-color: var(--nbs-navy-blue);

  &:hover {
    background: rgba(#023F85, 0.24);
  }

  &__icon {
    position: absolute;
    left: 2rem;
    margin-right: 1.2rem;
  }
}

// .button {
  
 
  
//   --padding-top: 20px;
//   --padding-bottom: 20px;

//   &--menu {
//     --background: transparent !important;
//     --background-hover: transparent !important;
//     --box-shadow: none;
//     --padding: 0;
//     --margin: 0;
//     padding: 0;
//     margin: 0;
//     height: 88px;
//     &:hover {
//       background-color: rgba(255, 255, 255, 0.5);
//     }
//   }

//   &--unstyled {
//     --color: var(--nbs-navy-blue);
//     --background: transparent !important;
//     --background-hover: transparent !important;
//     --box-shadow: none;
//   }

//   &--navy-blue {
//     --color: var(--white);
//   }

//   &--primary-blue {
//     color: var(--white);
//     background: var(--nbs-navy-blue);

//     &:hover {
//       background: var(--nbs-purple-dark);
//     }
//   }
// }}