.modal{

  &.inactive {
    display: none;
  }
  
  &.is-active {
    display: block;
  }

}