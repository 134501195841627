html {
  font-size: 62.5%;
}

body {
  font-family: $body-font-family;
  @include font-size($font-reg);
  line-height: 1.4;
  color: #4f4f50;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6{
  color: #4f4f50;
  font-family: $body-font-family;
}

// Paragraphs
p {
  color: #4f4f50;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

// Lists
.is-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Links
a {
  color: var(--nbs-navy-blue);
  text-decoration: none;
  font-family: $body-font-family;
  cursor: pointer;
}

// ------------------------------------------ \\
// Font Sizes
// ------------------------------------------ \\

@each $size, $value in $fontSizes {
  .is-font-#{$size} {
    font-size: $value;
  }
}

@each $breakpoint in $breakpoints {
  @include breakpoint-up($breakpoint) {
    @each $size, $value in $fontSizes {
      .#{$breakpoint}-is-font-#{$size} {
        font-size: $value;
      }
    }
  }
}

// ------------------------------------------ \\
// Font Weights
// ------------------------------------------ \\

@each $weight, $value in $fontWeights {
  .is-font-#{$weight} {
    font-weight: $value;
  }
}

// ------------------------------------------ \\
// Text Alignment
// ------------------------------------------ \\

.is-text-left {
  text-align: left;
}

.is-text-center {
  text-align: center;
}

.is-text-right {
  text-align: right;
}

@each $breakpoint in $breakpoints {
  @include breakpoint-up($breakpoint) {
    .#{$breakpoint}-is-text-left {
      text-align: left;
    }

    .#{$breakpoint}-is-text-center {
      text-align: center;
    }

    .#{$breakpoint}-is-text-right {
      text-align: right;
    }
  }
}

// ------------------------------------------ \\
// Text Transforms
// ------------------------------------------ \\

.is-text-uppercase {
  text-transform: uppercase;
}

// ------------------------------------------ \\
// Text Decoration
// ------------------------------------------ \\

.is-text-no-underline {
  text-decoration: none;
}

.is-text-underline {
  text-decoration: underline;
}
