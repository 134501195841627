.alert {
  display: flex;
  width: 100%;
  margin: 1.2rem 0;
  padding: 1.2rem;

  p {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
  }

  &--info {
    color: var(--nbs-navy-blue);
    font-weight: bold;
    background: var(--nbs-light-blue);
    border: solid 0.1rem var(--nbs-navy-blue);
  }

  &--error {
    color: var(--red);
    background-color: var(--red-200);
    border-radius: 0.4rem;
  }
}