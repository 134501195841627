// ------------------------------------------ \\
// CSS Variables
// ------------------------------------------ \\

:root {
  // Colours
  --white: #fff;
  --black: #000;
  --red-200: #FAC7D3;
  --red: #D50032;
  --orange: #f77f00;
  --orange-dark: #cc6900;
  --yellow: #fcbf49;
  --green: #00a896;
  --dark-blue: #003049;
  --light-grey: #eee;
  --theme-blue: #304C85;

  --nbs-purple: #753BBD;
  --nbs-purple-light: #8891BB;
  --nbs-purple-dark: #6b20b7;
  --nbs-violet: #B29CC2;
  --nbs-rose: #E2869D;
  --nbs-pink: #B8277D;
  --nbs-red: #D50032;
  --nbs-navy-blue: #023F85;
  --nbs-blue: #005CA9;
  --nbs-blue-light-100: #edf4fb;
  --nbs-blue-light: #d2e3f4;
  --nbs-black: #4E4E50;
  --nbs-ui-grey: #E9E5E5;
  --nbs-ui-grey-2: #D9D3D3;
  --nbs-light-grey: #efefef;
  --nbs-light-blue: #429FD9;
  --nbs-chatbot-grey: #465E71;

  //Font
  --ion-font-family: $body-font-family;
  --ion-text-color: #4f4f50;

  // Ionic Overrides
  --ion-background-color: #fff;
}

// ------------------------------------------ \\
// SASS Variables
// ------------------------------------------ \\

// Font Sizes
$font-xs: 10px;
$font-sm: 12px;
$font-reg: 16px;
$font-med: 24px;
$font-lg: 32px;
$font-xl: 44px;
$font-2xl: 52px;
$font-3xl: 60px;

// Breakpoints
$breakpoint-xs: 400px;
$breakpoint-sm: 400px;
$breakpoint-md: 667px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1600px;

// Spacers
$spacer-2xs: 0.8rem;
$spacer-xs: 1.6rem;
$spacer-sm: 2rem;
$spacer-md: 3.2rem;
$spacer-lg: 4rem;
$spacer-xl: 5.2rem;
$spacer-2xl: 6.4rem;
$spacer-3xl: 8rem;

// Main Typography
$body-font-family: 'Rubik', sans-serif !important;
$body-font-size: $font-reg;
$body-font-weight: 400;
$body-line-height: 1.56;

// Containers
$container-max-width: 144rem;
$container-lg-max-width: 180rem;

// Transitions
$transition-duration: 0.4s;
$transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

// ------------------------------------------ \\
// SASS Maps
// ------------------------------------------ \\

$colours: (
  'black': var(--black),
  'white': var(--white),
  'blue': var(--theme-blue),
  'nbs-black': var(--nbs-black)
);

$breakpoints: ('xs', 'sm', 'md', 'lg', 'xl', 'xxl');

$spacing: (
  'auto': auto,
  'none': 0,
  '2xs': $spacer-2xs,
  'xs': $spacer-xs,
  'sm': $spacer-sm,
  'md': $spacer-md,
  'lg': $spacer-lg,
  'xl': $spacer-xl,
  'xxl': $spacer-2xl,
);

$sizing: (
  '0': 0,
  '5': 5%,
  '10': 10%,
  '15': 15%,
  '20': 20%,
  '25': 25%,
  '30': 30%,
  '35': 35%,
  '40': 40%,
  '45': 45%,
  '50': 50%,
  '55': 55%,
  '60': 60%,
  '65': 65%,
  '70': 70%,
  '75': 75%,
  '80': 85%,
  '85': 85%,
  '90': 90%,
  '95': 95%,
  '100': 100%,
);

$flexValues: (
  'start': flex-start,
  'center': center,
  'end': flex-end,
  'between': space-between,
);

$flexModifiers: ('align', 'justify');

$displayValues: (
  'hidden': none,
  'block': block,
  'inline-block': inline-block,
  'inline': inline,
  'flex': flex,
  'inline-flex': inline-flex,
  'grid': grid,
);

$zIndexes: (
  '-1': -1,
  '0': 0,
  '100': 100,
  '200': 200,
  '300': 300,
  '400': 400,
  '500': 500,
  '600': 600,
  '700': 700,
  '800': 800,
  '900': 900,
  '1000': 1000,
);

$fontSizes: (
  'xs': $font-xs,
  'sm': $font-sm,
  'reg': $font-reg,
  'md': $font-med,
  'lg': $font-lg,
  'xl': $font-xl,
  '2xl': $font-2xl,
  '3xl': $font-3xl,
);

$fontWeights: (
  'thin': 100,
  'extra-light': 200,
  'light': 300,
  'regular': 400,
  'medium': 500,
  'semi-bold': 600,
  'bold': 700,
  'extra-bold': 800,
  'black': 900,
);
