@media (prefers-color-scheme: dark) {
  .md body, .ios body, .ios ion-modal, body {
    --ion-background-color: #fff;
    --ion-item-background: #fff;
    --ion-toolbar-background:#fff;;
    --ion-tab-bar-background: #fff;;
    --ion-card-background: #fff;;
  }
}

