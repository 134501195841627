@use 'sass:math';

// --------------------------------- \\
// Breakpoints
// --------------------------------- \\

$breakpoints-up: (
  'xs': (
    max-width: $breakpoint-xs,
  ),
  'sm': (
    min-width: $breakpoint-sm,
  ),
  'md': (
    min-width: $breakpoint-md,
  ),
  'lg': (
    min-width: $breakpoint-lg,
  ),
  'xl': (
    min-width: $breakpoint-xl,
  ),
  'xxl': (
    min-width: $breakpoint-2xl,
  ),
);

@mixin breakpoint-up($name) {
  @if map-has-key($breakpoints-up, $name) {
    @media #{inspect(map-get($breakpoints-up, $name))} {
      @content;
    }
  } @else {
    @warn "Couldn't find a breakpoint named `#{$name}`.";
  }
}

// --------------------------------- \\
// Font Size
// --------------------------------- \\

@function calculateRem($size) {
  $remSize: math.div($size, 16px) * 1.6rem;
  @return $remSize;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// --------------------------------- \\
// Transitions
// --------------------------------- \\

@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

// --------------------------------- \\
// Grid Layout
// --------------------------------- \\

@mixin grid-cols($columns: 1) {
  grid-template-columns: repeat($columns, 1fr);
}

@mixin grid-rows($rows: 1) {
  grid-template-rows: repeat($rows, 1fr);
}

// --------------------------------- \\
// Absolute Position
// --------------------------------- \\

@mixin absolute-position($top: auto, $right: auto, $bottom: auto, $left: auto) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: absolute;
}

// --------------------------------- \\
// Fixed Position
// --------------------------------- \\

@mixin fixed-position($top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: fixed;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// --------------------------------- \\
// Align Item Center Y
// --------------------------------- \\

@mixin position-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// --------------------------------- \\
// Responsive Image
// --------------------------------- \\

@mixin responsive-image {
  display: block;
  width: 100% !important;
  max-width: 100%;
  height: auto;
}

// --------------------------------- \\
// Image Object Fit
// --------------------------------- \\

@mixin object-fit-cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

// --------------------------------- \\
// Gradient
// --------------------------------- \\

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

// --------------------------------- \\
// Font Awesome Pseudo
// --------------------------------- \\

@mixin pseudo-font-awesome($icon) {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: $icon;
}

// --------------------------------- \\
// Border
// --------------------------------- \\

@mixin create-border($colour, $style, $width) {
  border-color: $colour;
  border-style: $style;
  border-width: $width;
}

// --------------------------------- \\
// Transparent Background
// --------------------------------- \\

@mixin transparent-background($color, $opacity) {
  background-color: rgba($color, $opacity);
}

// --------------------------------- \\
// Opacity Active
// --------------------------------- \\

@mixin active-opacity {
  opacity: 1;
  visibility: visible;
}
