@each $flex, $value in $flexValues {
  .align-#{$flex} {
    align-items: $value;
  }

  .justify-#{$flex} {
    justify-content: $value;
  }
}

@each $breakpoint in $breakpoints {
  @include breakpoint-up($breakpoint) {
    @each $flex, $value in $flexValues {
      .#{$breakpoint}-align-#{$flex} {
        align-items: $value;
      }

      .#{$breakpoint}-justify-#{$flex} {
        justify-content: $value;
      }
    }
  }
}
