$maroon-purple: #b13858 0%, #64097b 100%;
@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}
.swiper.mySwiper {
  padding-bottom: 7.2rem;

  .swiper-slide {
    height: auto;
  }
}

.swiperContainer {
  max-width: 128rem;
  margin: auto;
  ion-img {
    position: relative;
    &:before,
    &:after {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
    }

    &:before {
      background-color: #6b20b7;
      opacity: 0.3;
    }
  }
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 1.6rem;
    background-color: var(--white);
    width: calc(100% - 5rem);
    max-width: 52rem;
    left: 50%;
    transform: translateX(-50%);
    .swiper-scrollbar-drag {
      background-color: #2b55c4;
    }
  }
}

.swiperGridWrapper {
  padding-bottom: 12rem;
  position: relative;
  &::after {
    height: calc(50% + 14rem);
    z-index: -1;
    content: '';
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    @include gradient(123deg, $maroon-purple);
  }
}
