@each $size, $value in $spacing {
  // Margins
  .m-#{$size} {
    margin: $value;
  }

  .m-x-#{$size} {
    margin-left: $value;
    margin-right: $value;
  }

  .m-y-#{$size} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .m-t-#{$size} {
    margin-top: $value;
  }

  .m-r-#{$size} {
    margin-right: $value;
  }

  .m-b-#{$size} {
    margin-bottom: $value;
  }

  .m-l-#{$size} {
    margin-left: $value;
  }

  // Paddings
  .p-#{$size} {
    padding: $value;
  }

  .p-x-#{$size} {
    padding-left: $value;
    padding-right: $value;
  }

  .p-y-#{$size} {
    padding-top: $value;
    padding-bottom: $value;
  }

  .p-t-#{$size} {
    padding-top: $value;
  }

  .p-r-#{$size} {
    padding-right: $value;
  }

  .p-b-#{$size} {
    padding-bottom: $value;
  }

  .p-l-#{$size} {
    padding-left: $value;
  }
}

@each $breakpoint in $breakpoints {
  @include breakpoint-up($breakpoint) {
    @each $size, $value in $spacing {
      // Margins
      .#{$breakpoint}-m-#{$size} {
        margin: $value;
      }

      .#{$breakpoint}-m-x-#{$size} {
        margin-left: $value;
        margin-right: $value;
      }

      .#{$breakpoint}-m-y-#{$size} {
        margin-top: $value;
        margin-bottom: $value;
      }

      .#{$breakpoint}-m-t-#{$size} {
        margin-top: $value;
      }

      .#{$breakpoint}-m-r-#{$size} {
        margin-right: $value;
      }

      .#{$breakpoint}-m-b-#{$size} {
        margin-bottom: $value;
      }

      .#{$breakpoint}-m-l-#{$size} {
        margin-left: $value;
      }

      // Paddings
      .#{$breakpoint}-p-#{$size} {
        padding: $value;
      }

      .#{$breakpoint}-p-x-#{$size} {
        padding-left: $value;
        padding-right: $value;
      }

      .#{$breakpoint}-p-y-#{$size} {
        padding-top: $value;
        padding-bottom: $value;
      }

      .#{$breakpoint}-p-t-#{$size} {
        padding-top: $value;
      }

      .#{$breakpoint}-p-r-#{$size} {
        padding-right: $value;
      }

      .#{$breakpoint}-p-b-#{$size} {
        padding-bottom: $value;
      }

      .#{$breakpoint}-p-l-#{$size} {
        padding-left: $value;
      }
    }
  }
}
