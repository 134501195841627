
.nbs-toast {
  display:grid;
  grid-template-columns: 1fr 8rem;
  width: 100%;
  max-width: 300px;

  @media(min-width: 992px) {
    max-width: 600px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid 0.1rem var(--nbs-ui-grey);
    cursor: pointer;
  }
}