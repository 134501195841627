@each $display, $value in $displayValues {
  .is-display-#{$display} {
    display: $value;
  }
}

@each $breakpoint in $breakpoints {
  @include breakpoint-up($breakpoint) {
    @each $display, $value in $displayValues {
      .is-display-#{$breakpoint}-#{$display} {
        display: $value;
      }
    }
  }
}
