$footer-gradient: #b32442 0%, #6d2566 50%, #095798 100%;
@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

.site-footer {
  @include gradient(123deg, $footer-gradient);
  padding: 15rem 0 12rem;
  a,
  .ion-activatable {
    color: var(--white);
  }
  ion-list-header {
    --border-color: var(--white);
  }
  &__branding {
    margin-top: 12rem;
    margin-bottom: 2rem;
    position: relative;
    border: none;
    img {
      width: 30rem;
      height: auto;
    }
    &:before,
    &:after {
      position: absolute;
      width: 35%;
      height: 0.1rem;
      background-color: var(--white);
      content: '';
    }
    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}

.site-footer-mobile {
  box-shadow: inset 0px 3px 8px rgba(87, 87, 87, 0.1);
  background-color: var(--white);
  position: fixed;
  bottom: 0;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  section {
    padding: 0;
    ion-grid {
      padding: 0;
      ion-row {
        height: 80px;
        align-items: stretch;
        a,
        div {
          padding: 0;
          width: 80px;
          div {
            width: 22px;
            height: 22px;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          span {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.mobile-nav-menu {
  width: 100vw;
  transform: translate3d(100%, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 999;
  background: linear-gradient(
    172.33deg,
    #d50032 6.47%,
    #753bbd 50.75%,
    #023f85 95.03%
  );
  overflow-y: auto;
  &.is-active {
    transform: translate3d(0, 0, 0);
  }
}
