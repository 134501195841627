.divider {
  display: flex;
  align-items: center;
  position: relative;
  
  &:before,
  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    height: 0.2rem;
    background: #ebebeb;
    content: '';
  }
  
  &:after {
    right: 0;
  }
  
  span {
    position: relative;
    color: var(--nbs-black);
    // background: #121212;
    z-index: 10;
  }
  
}