.badge {
  display: inline-flex;
  justify-content: center;
  padding: 0.8rem 2rem;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 999px;

  &--purple {
    background-color: var(--nbs-purple)
  }

  &--blue-light {
    background-color: var(--nbs-light-blue)
  }

  &--nbs-navy-blue {
    background-color: var(--nbs-navy-blue)
  }

  &--nbs-violet {
    background-color: var(--nbs-violet)
  }
  &--nbs-red{
    background-color: var(--nbs-red);
  }

  &--nbs-rose {
    background-color: var(--nbs-rose);
  }

  &--nbs-pink {
    background-color: var(--nbs-pink);
  }
}