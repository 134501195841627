
@each $color, $value in $colours {
  .is-text-#{$color} {
      color: $value !important;
  }
}

@each $color, $value in $colours {
  .has-bg-#{$color} {
      background-color: $value !important;
  }
}

@each $color, $value in $colours {
  .has-border-#{$color} {
      border-color: $value !important;
  }
}