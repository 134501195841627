
// From Groups
.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: $spacer-sm;

  label {
    margin-bottom: 0.8rem;
    color: var(--nbs-black)
  }

  // label {
  //   clip: rect(1px, 1px, 1px, 1px);
  //   clip-path: inset(50%);
  //   height: 1px;
  //   width: 1px;
  //   margin: -1px;
  //   overflow: hidden;
  //   padding: 0;
  //   position: absolute;
  // }

  &--file-upload {
    width: 100%;
    padding: 3.2rem;
    // height: 20rem;
    
    color: var(--nbs-blue);
    font-weight: bold;
    background-color: var(--nbs-blue-light);
    border: 1px dashed #023F85;
    border-radius: 0.4rem;

    &.has-error {
     background-color: var(--red-200);
     border-color: var(--red);

    .form-error {
      justify-content: center;
     }
    }

    .form-error {
      width: 100%;
      color: var(--red);
      font-weight: 400;
    }

    > label,
    p {
      color: var(--nbs-blue);
    }

    p {
      margin: 1.2rem 0 0 0;
    }

    > label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      

      &:before {
        width: 3.2rem;
        height: 2.4rem;
        margin-bottom: 0.8rem;
        background-image: url(../../assets/icons/upload-icon.svg);
        background-size: contain;
        content: '';
      }

      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.4rem;
        padding: 1.6rem 2.4rem;
        font-size: 1.2rem;
        font-weight: bold;
        border: solid 0.2rem var(--nbs-blue);
        border-radius: 999px;
        content: 'CHOOSE FILE';

        &:hover {
          color: var(--white);
          background-color: var(--nbs-blue);
        }
      }

      &.has-error {
        color: var(--red);

        &:before {
          background-image: url(../../assets/icons/upload-icon-red.svg);
        }

        &:after {
          border-color: var(--red);
        }
      }

      .hs-form-required {
        display: none;
      }
    }
    input[type="file"] {
      display: none;
    }

    &.has-file {
      > label {
        &:after {
          display: none;
        }
      }
    }

    .button--remove {
      height: auto;
      margin: 0.8rem 0 0 0;
      font-size: 1.2rem;
      width: auto;
      margin: 0;
      top: 1rem;
      right: 1rem;
      text-transform: uppercase;
      padding: 1rem 2rem;
      background: var(--background);
      color: var(--colour);
      border-radius: 30px;
    }
  }
}


// Form Controls
.form-control,
.react-datepicker-wrapper input[type="text"] {
  width: 100%;
  padding: 1.2rem;
  color: var(--nbs-black);
  background: #fff;
  border: solid 0.1rem var(--nbs-ui-grey);
  border-radius: 0.4rem;

  
}

.form-control {

  &.has-error {
    color: var(--red);
    border: solid 0.2rem var(--red-200);
  }

  &:disabled {
    opacity: 0.5;
    background-color: var(--nbs-ui-grey);
    cursor: not-allowed;
  }

  &--number {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }
}

// Form Errors
.form-error {
  display: flex;
  margin-top: -0.2rem;
  padding: 1.2rem;
  color: var(--red);
  background-color: var(--red-200);
  border-radius: 0 0 0.4rem 0.4rem;

  a {
    padding: 0 0.4rem;
    color: var(--red);
    text-decoration: underline;
  }
}

// Form Input
.form-input {
  display: flex;
  
  .form-control {
    flex-grow: 1;
    margin-right: 0.8rem;
  }
}

// Select Inputs
.select-wrapper,
.form-group--select .select-wrapper {
  position: relative;
  
  &::after{
    pointer-events: none;
    position: absolute;
    right: 1.2rem;
    top: -0.5rem;
    color: var(--nbs-ui-grey);
    transform: rotate(180deg);
    content: "^";
    font-size: 32px;
    font-size: 3.2rem;
  }

  select {
    padding: 1.2rem;
    color: var(--nbs-black);
    background: var(--white);
    border: solid 0.1rem var(--nbs-ui-grey);
    border-radius: 0.4rem;
    height: 5.2rem;
    width: 100%;
    // margin: 1.2rem 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:disabled{
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }
}


// Hubspot Forms
.hs-form {
  width: 100%;

  label,
  legend {
    color: var(--nbs-black);
  }

  legend {
    font-size: 1.4rem;
  }

  // Inputs
  input[type="text"],
  input[type="email"],
  input[type="number"]
  textarea {
    @extend .form-control;

    margin: 1.2rem 0;

    &.error {
      margin-bottom: 0;
    }
  }
  textarea {
    width: 100%;
    height: 16rem;
    padding: 1.2rem;
    color: var(--nbs-black);
    background: var(--white);
    border: solid 0.1rem var(--nbs-ui-grey);
    border-radius: 0.4rem;
  }
  .hs-fieldtype-select{
    .input{
      position: relative;
      &::after{
        pointer-events: none;
        position: absolute;
        right: 1.2rem;
        top: 0.5rem;
        color: #095798;
        transform: rotate(180deg);
        content: "^";
        font-size: 32px;
        font-size: 3.2rem;
      }
    }
  }
  select {
    padding: 1.2rem 2.1rem;
    background: var(--white);
    border: solid 0.1rem #D0D0D0;
    border-radius: 0.4rem;
    height: 5.2rem;
    width: 100%;
    margin: 1.2rem 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:disabled{
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }

  // File upload
  .hs-fieldtype-file {
    margin: 1.6rem 0;

    > label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 20rem;
      
      color: var(--nbs-blue);
      font-weight: bold;
      background-color: var(--nbs-blue-light);
      border: 1px dashed #023F85;
      border-radius: 0.4rem;

      &:before {
        width: 3.2rem;
        height: 2.4rem;
        margin-bottom: 0.8rem;
        background-image: url(../../assets/icons/upload-icon.svg);
        background-size: contain;
        content: '';
      }

      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.4rem;
        padding: 1.6rem 2.4rem;
        font-size: 1.2rem;
        font-weight: bold;
        border: solid 0.2rem var(--nbs-blue);
        border-radius: 999px;
        content: 'CHOOSE FILE';

        &:hover {
          color: var(--white);
          background-color: var(--nbs-blue);
        }
      }

      .hs-form-required {
        display: none;
      }
    }
    input[type="file"] {
      display: none;
    }
  }

  // Error Messages
  .hs-error-msgs {
    margin: 0;
    padding: 0;

    li {
      label {
        display: flex;
        justify-content: center;
        margin-top: 0.8rem;
        padding: 1.2rem;
        color: var(--nbs-red);
        font-size: 1.4rem;
        font-weight: bold;
        background-color: var(--red-200);
        border-radius: 0.4rem;
      }
    }
  }

  .hs_error_rollup {
    label {
      display: flex;
      justify-content: center;
      padding: 1.2rem;
      background-color: var(--red-200);
    }
  }

  .hs-button {
    @extend .button;
    color: var(--white);
    justify-content: center;
    width: 100%;
    margin-top: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    background-color: var(--nbs-blue);
    border: none;
    border-radius: 999px;
  }

}

.form-info {
  font-size: 1.4rem;
}


.form-delete {
  p {
    margin: 0 0 3.6rem;
    text-align: right;
  }
}

.form-checkbox-group {
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    position: relative;
    margin-bottom: 1.6rem;

    span {
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin-right: 1.6rem;
        border: solid 0.1rem var(--nbs-ui-grey);
        content: '';
      }
    }
  

    input[type="checkbox"] {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;

      &:checked {
        ~ span {
          &:before {
            color: var(--white);
            font-size: 1.6rem;
            background: var(--nbs-blue);
            border-color: var(--nbs-blue);
            content: '✓';
          }
        }
      }
    }

  }  
}

//React Select
.modal-popup{
  .form-group .react-select-container{
    .react-select{
      &__control{
        border: solid 0.1rem var(--nbs-ui-grey);
        border-radius: 0.4rem;
      }
    }
  }
}
.form-group .react-select-container {
  margin-top: 2rem;
  padding: 0;
  .react-select{
    &__control{
      padding: 0rem;
      border: solid 0.1rem var(--nbs-ui-grey);
      border-radius: 0.4rem;
    }
    &__value-container{
      padding: 0.8rem 0.8rem;
    }
  }
}

.filters-wrapper{
  .react-datepicker-wrapper{
  }
  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
    background-color: rgb(10, 92, 169)
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
    &:hover{
      border-radius: 50%;
    }
  }
  
  .react-datepicker__day-name{
    color: #fff;
  }
  .react-datepicker__current-month {
    font-size: 1em;
    color: #fff;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
  
  .react-datepicker__day--in-range{
    background-color: #0a5ca9;
    border-radius: 50%;;
    &:hover{
      border-radius: 50%;;
    }
  }
  .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range{
    background-color: #0a5ca9;
    color: #fff;
  }
}